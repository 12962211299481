import React, { useEffect, useRef } from 'react'
import gsap, { TweenMax } from 'gsap'
import DrawSVGPlugin from 'gsap/DrawSVGPlugin'

import styled from '@xstyled/styled-components'

const ActiveIndicator: React.FC = () => {
  const circleRef = useRef(null)

  useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin)
    TweenMax.set(circleRef.current, { drawSVG: '0' })
    TweenMax.fromTo(
      circleRef.current,
      12,
      { drawSVG: '0' },
      { drawSVG: '0% 100%', ease: 'linear.none', opacity: 1 },
    )
  })

  return (
    <ActiveIndicatorWrap>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
        <circle
          ref={circleRef}
          cx="5"
          cy="5"
          r="4"
          stroke="white"
          strokeWidth="1"
          fill="transparent"
          transform="rotate(-9, 5, 5)"
        />
        <circle
          cx="5"
          cy="5"
          r="4"
          stroke="rgba(255, 255, 255, 0.2)"
          strokeWidth="1"
          fill="transparent"
          transform="rotate(-9, 5, 5)"
        />
      </svg>
    </ActiveIndicatorWrap>
  )
}

const ActiveIndicatorWrap = styled.div`
  height: 12px;
  width: 12px;
  svg {
    height: 12px;
    width: 12px;
  }
`

export default ActiveIndicator
