import React from 'react'
import Page from '@/components/Page'
import { Box } from '@xstyled/styled-components'
import PageWrapper from '@/components/PageWrapper'
import WithTestimonialSlider from '@/components/Helpers/WithTestimonialSlider'

import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Right } from '@/layouts/styled'
import TestimonialFollowUp from '@/components/TestimonialFollowUp'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    allCmsCase(filter: { featured: { eq: true } }) {
      edges {
        node {
          id
          slug
          title
          pretitle
          subtitle
          without_followup
          client_quote_gender
          client_header
          client_text
          client_footer
          title_width
          overview_image {
            data {
              url
            }
          }
        }
      }
    }
  }
`

interface TestimonialsInterface {
  allCmsCase: GatsbyTypes.CmsCaseConnection
}

interface overviewImage {
  data: {
    url: string
  }
}

interface linkInterface {
  link: string
  url: string
  variant: string
}

export interface CaseType {
  id: string
  route: string
  noFollowup: boolean
  overviewImage: overviewImage
  title: string
  nameCompany: string
  slug: string
  gender: string
  text: string
  pretitle: string
  links: linkInterface[]
}

const TestimonialsPage: React.FC<{ data: TestimonialsInterface }> = ({
  data,
}) => {
  const inProp = useContentInProp()
  const { t } = useTranslation()
  /* eslint-disable camelcase */
  /* eslint-disable indent */

  const transformRecord = ({ node }) => ({
    id: node.id,
    route: '/clients-and-cases',
    noFollowup: node?.without_followup,
    overviewImage: node?.overview_image,
    title: node?.title,
    nameCompany: node?.client_name,
    slug: node?.slug,
    gender: node?.client_quote_gender,
    text: node?.subtitle,
    pretitle: node?.pretitle,
    links: !node?.without_followup
      ? [
          {
            link: t('cases.testimonials_fullstory'),
            url: '/clients-and-cases',
            variant: 'mdDown',
          },
          {
            link: t('cases.testimonials_cta'),
            url: '/home/followup',
            variant: 'all',
          },
        ]
      : [
          {
            link: t('cases.testimonials_cta'),
            url: '/home/followup',
            variant: 'all',
          },
        ],
  })
  /* eslint-enable indent */
  /* eslint-enable camelcase */
  const cases = data.allCmsCase.edges.map(transformRecord)

  return (
    <>
      <Right>{inProp && <TestimonialFollowUp cases={cases} />}</Right>
      <Page
        content={
          <PageWrapper>
            <Box row>
              <Box col={{ xs: 1, md: 0.6 }}>
                <WithTestimonialSlider cases={cases} />
              </Box>
              <Box col={0.4} />
            </Box>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default TestimonialsPage
