import React, { useEffect } from 'react'
import { TestimonialContainer } from './styled'
import { Text } from '@/components/Text'
import PubSub from 'pubsub-js'
import { APP_PAGE_ANIMATION_COMPLETED } from '@/events'
import styled, { css } from '@xstyled/styled-components'
import ActiveIndicator from './ActiveIndicator'
import { fadeIn } from '../Certificates/styled'
import { breakpoints } from '@xstyled/system'
import { CaseType } from '@/pages/home/testimonials'

const TestimonialExcerpt: React.FC<{
  caseEntry: CaseType
  cases: CaseType[]
  setNewCase: (caseId) => void
}> = ({ caseEntry, cases, setNewCase }) => {
  let timeoutHandle = null

  const onHandleReady = () => {
    PubSub.publish(APP_PAGE_ANIMATION_COMPLETED)
  }
  const onHandleIndicatorClick = (index) => {
    if (timeoutHandle) {
      clearTimeout(timeoutHandle)
    }
    setNewCase(index)
  }
  useEffect(() => {
    timeoutHandle = setTimeout(onHandleReady, 500)
    return () => {
      clearTimeout(timeoutHandle)
    }
  }, [])
  return (
    <TestimonialContainer>
      <Text
        {...caseEntry}
        withIndicators={
          <>
            <IndicatorContainer>
              {cases.map(
                (data, index) =>
                  data.title === caseEntry.title ? (
                    <ActiveIndicator key={data.id} data-url />
                  ) : (
                    <Indicator
                      key={data.id}
                      data-url
                      onClick={() => {
                        onHandleIndicatorClick(index)
                      }}
                    />
                  ),
                // eslint-disable-next-line
              )}
            </IndicatorContainer>
          </>
        }
      />
    </TestimonialContainer>
  )
}

const IndicatorContainer = styled.div`
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in forwards;
  ${breakpoints({
    md: css`
      margin-top: -20px;
    `,
  })}
  padding-bottom: 20px;
`
const Indicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
`

TestimonialExcerpt.defaultProps = {}

TestimonialExcerpt.propTypes = {}

export default TestimonialExcerpt
