import React, { useState, useEffect } from 'react'
import { APP_PAGE_TESTIMONIAL_SLIDE_UPDATE } from '../../events'
import PubSub from 'pubsub-js'
import { SideNavigationLink } from '../SideNavigationLink'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { CaseType } from '@/pages/home/testimonials'

export const TestimonialFollowUp: React.FC<{ cases: CaseType[] }> = ({
  cases,
}) => {
  const [caseNumber, setCaseNumber] = useState(0)
  const { t } = useTranslation()
  function onHandleCaseUpdate(eventName, newCaseNumber) {
    setCaseNumber(newCaseNumber)
  }
  useEffect(() => {
    const token = PubSub.subscribe(
      APP_PAGE_TESTIMONIAL_SLIDE_UPDATE,
      onHandleCaseUpdate,
    )

    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])
  return (
    !cases[caseNumber]?.noFollowup && (
      <SideNavigationLink
        variant="light"
        position="right"
        delay={1.1}
        time={1}
        url={`${cases[caseNumber]?.route}`}
        text={t('cases.testimonials_fullstory')}
      />
    )
  )
}

export default TestimonialFollowUp
