import React, { useState, useEffect, useRef } from 'react'
import PubSub from 'pubsub-js'
import {
  APP_PAGE_TESTIMONIAL_SLIDE_UPDATE,
  APP_SWITCH_BACKGROUND_THREE_SCENE,
  APP_PAGE_ANIMATION_COMPLETED,
} from '@/events'
import styled from '@xstyled/styled-components'
import TestimonialExcerpt from '../TestimonialExcerpt/TestimonialExcerpt'
import gsap from 'gsap'
import { CaseType } from '@/pages/home/testimonials'

const WithTestimonialSlider: React.FC<{
  cases: CaseType[]
}> = ({ cases }) => {
  const [view] = useState(null)
  const [slide, setSlide] = useState(0)
  const containerRef = useRef(null)
  // const [timeoutHandle, setTimeoutHandle] = useState(null)
  const timeoutHandle = useRef(null)
  const showPageTimeout = 12000

  const onHandleAnimationEnd = (newSlide = null) => {
    clearTimeout(timeoutHandle.current)
    if (newSlide !== null) {
      setSlide(newSlide)
    } else {
      setSlide(
        (currentSlide) =>
          currentSlide < cases?.length - 1 ? currentSlide + 1 : 0,
        // eslint-disable-next-line
      )
    }
    // eslint-disable-next-line
    timeoutHandle.current = setTimeout(showNextSlide, showPageTimeout)
  }

  const showNextSlide = (newSlide = null) => {
    clearTimeout(timeoutHandle.current)
    const tl = gsap.timeline({
      repeat: 0,
      timeScale: 1,
      onComplete: () => onHandleAnimationEnd(newSlide),
    })
    tl.set(containerRef.current, {
      opacity: 1,
    })
    tl.to(
      containerRef.current,
      0.5,
      {
        opacity: 0,
      },
      0,
    )
  }
  const goToSlide = (newSlide) => {
    showNextSlide(newSlide)
    // setTimeoutHandle(setTimeout(showNextSlide, showPageTimeout))
  }
  useEffect(() => {
    clearTimeout(timeoutHandle.current)
    PubSub.publish(APP_PAGE_TESTIMONIAL_SLIDE_UPDATE, slide)
  }, [slide])

  const onSlideShowStart = () => {
    timeoutHandle.current = setTimeout(showNextSlide, showPageTimeout)
    PubSub.publish(APP_PAGE_TESTIMONIAL_SLIDE_UPDATE, 0)
  }

  useEffect(() => {
    const activeCase = cases[slide]
    if (activeCase.overviewImage) {
      PubSub.publish(APP_SWITCH_BACKGROUND_THREE_SCENE, {
        name: `${activeCase.slug}_scene`,
        pageReadyDelay: 3,
        mediaSettings: {
          mediaHTMLElement: activeCase?.overviewImage?.data?.url,
          // position: { x: 0.75, y: 0.5 },
        },
      })
    } else {
      const sceneName = { w: 'femalehead', m: 'malehead' }[activeCase.gender]
      PubSub.publish(APP_SWITCH_BACKGROUND_THREE_SCENE, {
        sceneName,
      })
      PubSub.publish(APP_PAGE_ANIMATION_COMPLETED)
    }
  }, [slide])

  useEffect(() => {
    onSlideShowStart()
    return () => {
      clearTimeout(timeoutHandle.current)
    }
  }, [view])

  const onHandleSetCase = (caseId) => {
    clearTimeout(timeoutHandle.current)
    goToSlide(caseId)
  }
  /* eslint-disable indent */

  return (
    <>
      {!!cases &&
        cases.map(
          (caseEntry, index) =>
            slide === index && (
              <DynamicWidthContainer key={caseEntry.id} ref={containerRef}>
                <TestimonialExcerpt
                  setNewCase={(caseId) => {
                    onHandleSetCase(caseId)
                  }}
                  cases={cases}
                  caseEntry={caseEntry}
                />
              </DynamicWidthContainer>
            ),
        )}
    </>
  )
}

const DynamicWidthContainer = styled.div`
  width: 100%;
`

export default WithTestimonialSlider
